import { auth, provider, firestore } from "../utils/firebase.js";
import { collection } from "firebase/firestore";
import { API_BASE_URL } from "../utils/config.js";
import { useState, useEffect } from "react";

export function useFetchPosts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getPosts() {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/posts`);
        const data = await response.json();
        setPosts(data.posts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    }

    getPosts();
  }, []);

  return { posts, loading };
}
export async function createPost(newPost) {
  const idToken = localStorage.getItem("idToken");
  const uid = localStorage.getItem("uid");

  try {
    const response = await fetch(`${API_BASE_URL}/posts?uid=${uid}`, {
      method: "POST",
      headers: {
        Authorization: idToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPost),
    });
    const data = await response.json();
    console.log("Post created:", data);
    return data;
  } catch (error) {
    console.error("Error creating post:", error);
    throw error;
  }
}
