import { API_BASE_URL } from "../utils/config.js";
import db from "../utils/firebase.js";
import {
  getDoc,
  updateDoc,
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { sendShareJobThanksEmail } from "../utils/Email.js";

const PAGE_SIZE = 20;

export const fetchJobsData = async ({
  category = null,
  company = null,
  days_since_posted = null,
  location = null,
  title = null,
  yoe_less_than = null,
  page_number = 1,
  page_size = PAGE_SIZE,
  job_category = 0, // 0 -- SWE, 1 -- PM, 2 -- DS
} = {}) => {
  // console.log(page_number, "page number");
  try {
    // Construct the URL with query parameters
    const queryParams = new URLSearchParams();
    if (category) queryParams.append("category", category);

    // Handle array of companies
    if (Array.isArray(company) && company.length > 0) {
      const companyString = company.join(",");
      queryParams.append("company", companyString);
    }

    if (days_since_posted) {
      queryParams.append("days_since_posted", days_since_posted);
    }
    if (location) {
      queryParams.append("location", location);
    }
    if (title) {
      queryParams.append("title", title);
    }
    if (yoe_less_than !== null) {
      queryParams.append("yoe_less_than", yoe_less_than);
    }

    queryParams.append("page_number", page_number);
    queryParams.append("page_size", page_size);

    let url;
    if (job_category == 0) {
      url = `${API_BASE_URL}/jobs/sde?${queryParams.toString()}`;
    } else if (job_category == 1) {
      url = `${API_BASE_URL}/jobs/pm?${queryParams.toString()}`;
    } else if (job_category == 2) {
      url = `${API_BASE_URL}/jobs/ds?${queryParams.toString()}`;
    }
    // console.log("Fetching from URL:", url);

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    // console.log("Jobs Data from backend:", data); // Debugging line

    // Assuming you have a function to set the job list, similar to setCompanyList
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const fetchRecommendedJobs = async (job_category, yoe) => {
  let collectionName = "";

  // Determine the collection name based on the job category
  switch (job_category) {
    case 0:
      collectionName = "linkedin_jobs"; // Replace with your actual collection name for SWE
      break;
    case 1:
      collectionName = "linkedin_pm_jobs"; // Replace with your actual collection name for PM
      break;
    case 2:
      collectionName = "linkedin_ds_jobs"; // Replace with your actual collection name for DS
      break;
    default:
      throw new Error("Invalid job category");
  }

  let q;

  if (yoe == "intern") {
    q = query(
      collection(db, collectionName),
      where("isRecommended", "==", true),
      where("yoe", "==", yoe) // Add this line to filter by years of experience
    );
  } else {
    // Define the query
    q = query(
      collection(db, collectionName),
      where("isRecommended", "==", true),
      where("yoe", "!=", "intern")
    );
  }

  // Fetch the data
  const querySnapshot = await getDocs(q);
  const jobs = [];
  querySnapshot.forEach((doc) => {
    jobs.push({ id: doc.id, ...doc.data() });
  });
  return jobs;
};

export const calculatePostTime = (dateAdded, isRecommended = false) => {
  const currentDate = new Date(); // Gets the current date
  const addedDate = new Date(dateAdded); // Directly parse the provided date string

  let days;

  if (isRecommended) {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day

    const dateAddedTimestamp = new Date(
      dateAdded.seconds * 1000 + dateAdded.nanoseconds / 1000000
    );
    // Calculate the difference in days
    days = Math.round(Math.abs((currentDate - dateAddedTimestamp) / oneDay));
  }
  // Calculate the difference in days
  else days = Math.floor((currentDate - addedDate) / (1000 * 60 * 60 * 24));

  const formattedDate = addedDate.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
  });

  let postTime;

  if (days === 0) {
    postTime = "today";
  } else if (days === 1) {
    postTime = "yesterday";
  } else if (days <= 6) {
    postTime = `${days} d ago`;
  } else if (days <= 13) {
    postTime = "1 w ago";
  } else if (days <= 20) {
    postTime = "2 w ago";
  } else if (days <= 28) {
    postTime = "3 w ago";
  } else if (days <= 58) {
    postTime = "1 month ago";
  } else {
    postTime = "months ago";
  }

  return {
    days,
    postTime,
    formattedDate,
  };
};

export const getLabelColors = (days) => {
  if (days <= 1) {
    return { bgColor: "bg-green-200", textColor: "text-green-800" };
  } else if (days <= 3) {
    return { bgColor: "bg-blue-200", textColor: "text-blue-800" };
  } else if (days <= 13) {
    return { bgColor: "bg-blue-200", textColor: "text-blue-800" };
  }

  return { bgColor: "bg-gray-200", textColor: "text-gray-800" };
};

export async function handleCopyJobtoClipboard(job) {
  const formattedJob = `${job.title} @${job.company}\n${
    job.apply_link ? `link: ${job.apply_link}` : `link: ${job.link}`
  }`;

  try {
    await navigator.clipboard.writeText(formattedJob);
    return true; // Return true if the copy operation is successful
  } catch (error) {
    console.error("Failed to copy job information: ", error);
    return false; // Return false if the copy operation fails
  }
}

export async function addClickCount(jobID, PMPage, DSPage, userID) {
  let jobRef;
  if (PMPage) {
    jobRef = doc(db, "linkedin_pm_jobs", jobID);
  } else if (DSPage) {
    jobRef = doc(db, "linkedin_ds_jobs", jobID);
  } else {
    jobRef = doc(db, "linkedin_jobs", jobID);
  }

  try {
    // Try to get the document
    const docSnap = await getDoc(jobRef);

    if (docSnap.exists()) {
      // If the document exists
      const jobData = docSnap.data();

      if (jobData.click_count) {
        // If click_count already exists, increment it
        const newClickCount = jobData.click_count + 1;

        await updateDoc(jobRef, {
          click_count: newClickCount,
        });

        if (newClickCount === 10) {
          // Call the function when click_count reaches 10
          await sendShareJobThanksEmail(jobData, userID);
        }
      } else {
        // If click_count does not exist, set it to 1
        await updateDoc(jobRef, { click_count: 1 });
      }
    } else {
      // If the document does not exist, create it with click_count set to 1
      await setDoc(jobRef, { click_count: 1 });
    }
  } catch (error) {
    console.error("Error updating document: ", error);
  }
}

export async function approveJob(job, approverId) {
  let jobRef;

  jobRef = doc(db, "user_uploaded_jobs", job.job_id);

  await updateDoc(jobRef, { approved: true });

  const SWEJobsCollection = collection(db, "linkedin_jobs");
  const PMJobsCollection = collection(db, "linkedin_pm_jobs");
  const DSJobsCollection = collection(db, "linkedin_ds_jobs");

  // Construct job object with approver field
  const jobData = {
    ...job,
    approver: approverId,
  };

  let ApprovedJobRef;

  // Determine category and add job to respective collection
  if (job.category === "Software Engineer") {
    ApprovedJobRef = await addDoc(SWEJobsCollection, jobData);
  } else if (job.category === "Product Manager") {
    ApprovedJobRef = await addDoc(PMJobsCollection, jobData);
  } else if (job.category === "Data Scientist") {
    ApprovedJobRef = await addDoc(DSJobsCollection, jobData);
  }

  console.log(jobData);

  return ApprovedJobRef;
}

export async function deleteNotApprovedJob(job) {
  try {
    await deleteDoc(doc(db, "user_uploaded_jobs", job.job_id));
    console.log(`${job.title} at ID ${job.company} deleted successfully.`);
  } catch (error) {
    console.error("Error deleting job: ", error);
  }
}
