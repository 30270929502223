import React from "react";

const PostItem = ({
  company,
  postTitle,
  username,
  content,
  comments_count,
  likes_count,
  jobTitle,
}) => {
  return (
    <div className="m-4 border-b border-gray-300">
      <div className="pb-4 text-left">
        <h4>
          <span className="text-blue-600">{company}</span>
          <span className="text-gray-400"> {jobTitle}</span>
        </h4>
        <p>
          <strong>{postTitle} </strong>
          <span className="text-blue-600">@{username}</span>
          <br />
          {content}
        </p>
        <div className="flex items-center justify-between mt-4">
          <div className="flex space-x-4">
            <span className="text-sm flex items-center">
              💬 {comments_count}
            </span>
            <span className="text-sm flex items-center">👀 {likes_count}</span>
          </div>
          <div className="flex space-x-2">
            <span className="bg-gray-200 text-sm px-2 py-1 rounded-full">
              OA
            </span>
            <span className="bg-gray-200 text-sm px-2 py-1 rounded-full">
              Interview
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostItem;
