import React, { useEffect, useState } from "react";
import { useGlobalState } from "../state";
import JobItem from "./JobItem"; // Adjust path as per your project structure
import Header from "../layout/Header";
import axios from "axios";
import { calculatePostTime, approveJob, deleteNotApprovedJob } from "./Job"; // Import your utility function for calculating post time
import { API_BASE_URL } from "../utils/config.js";

const ApproveJob = () => {
  const [jobs, setJobs] = useState([]);
  const userID = useGlobalState("user_id");

  useEffect(() => {
    const fetchJobs = async () => {
      const idToken = localStorage.getItem("idToken");
      if (!idToken) {
        console.error("No ID token found");
        return;
      }

      try {
        const response = await axios.get(`${API_BASE_URL}/unapproved_jobs`, {
          headers: {
            Authorization: idToken,
          },
        });
        setJobs(response.data);
      } catch (error) {
        console.error("Error fetching jobs: ", error);
      }
    };

    fetchJobs();
  }, []); // Empty dependency array means this effect runs once after initial render

  const processJobData = (jobs) => {
    return jobs.map((job) => {
      const { days, postTime, formattedDate } = calculatePostTime(
        job.date_added
      );
      return {
        id: job.job_id,
        ...job,
        days,
        postTime,
        formattedDate,
      };
    });
  };

  useEffect(() => {
    if (jobs.length > 0) {
      const processedJobs = processJobData(jobs);
      if (
        processedJobs.some(
          (processedJob, index) => processedJob.id !== jobs[index].id
        )
      ) {
        setJobs(processedJobs);
      }
    }
  }, [jobs.length]); // Only run when jobs.length changes (initial fetch)

  const handleDelete = async (job) => {
    try {
      await deleteNotApprovedJob(job);
      // Update state to remove the deleted job from UI
      setJobs((prevJobs) => prevJobs.filter((j) => j.job_id !== job.job_id));
    } catch (error) {
      console.error("Error deleting job: ", error);
    }
  };

  // const handleDelete = async (job) => {
  //   const idToken = localStorage.getItem("idToken");
  //   if (!idToken) {
  //     console.error("No ID token found");
  //     return;
  //   }

  //   try {
  //     await axios.delete(`${API_BASE_URL}/delete_job`, {
  //       headers: {
  //         Authorization: idToken,
  //       },
  //       data: { job_id: job.job_id },
  //     });
  //     setJobs((prevJobs) => prevJobs.filter((j) => j.job_id !== job.job_id));
  //   } catch (error) {
  //     console.error("Error deleting job: ", error);
  //   }
  // };

  const handleApprove = async (job) => {
    try {
      // Clone the job object and remove unsupported fields (if any)
      const jobToApprove = { ...job };

      // Ensure there are no functions or undefined values in jobToApprove
      for (const key in jobToApprove) {
        if (
          typeof jobToApprove[key] === "function" ||
          typeof jobToApprove[key] === "undefined"
        ) {
          delete jobToApprove[key];
        }
      }

      await approveJob(jobToApprove, userID[0]); // Pass the cleaned job object
      console.log(`${job.title} at ID ${job.company} approved successfully.`);
      setJobs((prevJobs) => prevJobs.filter((j) => j.job_id !== job.job_id));
      // Example: Move job to another collection or update its status
    } catch (error) {
      console.error("Error approving job: ", error);
    }
  };

  // const handleApprove = async (job) => {
  //   console.log(job);
  //   const idToken = localStorage.getItem("idToken");
  //   if (!idToken) {
  //     console.error("No ID token found");
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(
  //       `${API_BASE_URL}/approve_job`,
  //       { job_id: job.job_id },
  //       {
  //         headers: {
  //           Authorization: idToken,
  //         },
  //       }
  //     );

  //     // Assuming the response is successful and job is removed from the list
  //     setJobs((prevJobs) => prevJobs.filter((j) => j.job_id !== job.job_id));
  //   } catch (error) {
  //     console.error("Error approving job: ", error);
  //     if (error.response) {
  //       console.error(
  //         "Server responded with status code: ",
  //         error.response.status
  //       );
  //       console.error("Server error message: ", error.response.data.error); // Adjust according to your API response format
  //     } else if (error.request) {
  //       console.error(
  //         "Request was made but no response received: ",
  //         error.request
  //       );
  //     } else {
  //       console.error("Error setting up request: ", error.message);
  //     }
  //   }
  // };

  return (
    <div>
      <Header />
      <div className="mt-24 max-w-4xl mx-auto">
        <h1 className="text-2xl font-bold mb-4">Approve Jobs</h1>
        <div className="">
          {jobs.map((job) => (
            <div className="mb-4" key={job.job_id}>
              <JobItem
                key={job.job_id}
                job={job}
                isSponsorH1B={true}
                setIsSponsorH1B={(value) => {}}
                firstOne={false}
                InternPage={false}
                PMPage={false}
                DSPage={false}
                todayLast={false}
              />
              <div className="flex justify-end mb-2">
                <button
                  onClick={() => handleDelete(job)}
                  type="button"
                  className="flex py-1.5 px-3 me-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  Delete
                </button>
                <button
                  onClick={() => handleApprove(job)}
                  type="button"
                  className="flex text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-xs px-3 py-1.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Approve
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApproveJob;
